<template>
  <div class="dashboard-widget-template">
    <div
      v-html="data.text"
      :style="`
        padding: ${data.padding ? data.padding : 0}px;
        font-size: ${data.fontSize ? data.fontSize : 12}px;
        color: ${data.color ? data.color : 'black'};
      `"
    >
    </div>
  </div>
</template>

<script>
import {WidgetViewMixin} from "@/components/home/dashboard/widgets/WidgetViewMixin";

export default {
  name: 'TextWidgetTemplate',
  mixins: [WidgetViewMixin],
  props: {},
  methods: {}
}
</script>